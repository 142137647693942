<template>
  <el-tabs v-model="activeName" class="cat-bar" @tab-click="handleChangeCategory">
    <el-tab-pane
      v-for="(option, index) in catOptions"
      :key="index"
      :label="option.title"
      :name="option.id"
    />
  </el-tabs>
</template>

<script>
  export default {
    name: "CatBar",
    props: {
      catOptions: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        activeName: ''
      }
    },
    watch: {
      'catOptions.length': {
        handler() {
          const id = this.$route.query.id
          this.activeName = id ? id : this.catOptions[0].id
        }
      }
    },
    methods: {
      handleChangeCategory(el) {
        if (el.name !== this.$route.query.id) this.jumpTo(this.$route.name, { id: el.name })
      }
    }
  }
</script>

<style scoped lang="scss">
  .cat-bar {
    height: 41px;
    border: 1px solid $color-border;
    margin-top: 30px;
    padding: 0 30px;
  }
</style>

<style lang="scss">
  .cat-bar {
    .el-tabs__nav-wrap::after{
      display: none;
    }
    .el-tabs__nav-scroll {
      display: flex;
      justify-content: center;
    }
  }
</style>