<template>
  <div class="project-page">
    <div class="safe-area-banner">
      <div class="fs32">行愿项目</div>
      <div class="fs16 desc">
        基金会在四川省民政厅和四川省宗教局的管理和指导下，严格按照国务院《基金会管理条例》的规定，<br>
        开展赈灾、助学、济困、悯孤、养老、助残等慈善活动以及依法开展其他社会慈善公益活动
      </div>
    </div>
    <cat-bar :cat-options="projectOptions"/>
    <div v-if="currentProject.id" class="content-panel">
      <div class="title fs22">{{ currentProject.title }}</div>
      <div class="subtitle">{{ currentProject.subtitle }}</div>

      <div class="text-wrapper" v-html="currentProject.desc"></div>
    </div>
  </div>
</template>

<script>
  import CatBar from "@/components/Common/CatBar";
  import { emeiApi } from "@/service/api/emei";

  export default {
    name: "Project",
    components: { CatBar },
    data() {
      return {
        projectOptions: [],
      }
    },
    computed: {
      currentProject() {
        if (this.projectOptions.length === 0) return {}
        return this.projectOptions.find(item => item.id === this.$route.query.id) || this.projectOptions[0]
      }
    },
    mounted() {
      emeiApi.getProjectList().then(({ result }) => {
        this.projectOptions = result.map(item => ({
          id: item.id,
          title: item.title,
          subtitle: item.secondTitle,
          desc: item.content,
          img: item.bgImg ? item.bgImg : '',
        }))
      })
    }
  }
</script>

<style scoped lang="scss">
  .project-page {
    .safe-area-banner {
      background: url("~@/assets/images/project_banner.jpg") center;
      .desc {
        margin-top: 30px;
        color: rgba(white, 0.8);
        text-align: center;
        line-height: 30px;
      }
    }
    .content-panel {
      margin-top: 30px;
      margin-bottom: 70px;
      padding: 30px 254px 40px;
      text-align: center;
      background-color: $color-bg-dark;
      .title {
        font-weight: bold;
        margin-bottom: 22px;
      }
      .subtitle {
        color: $font-color-2;
        margin-bottom: 40px;
      }
      .project-img {
        max-width: 100%;
      }
      .carousel-wrapper {
        width: 691px;
        margin: 0 auto;
        .banner-img {
          width: 100%;
          height: 346px;
          border-radius: 2px;
        }
      }
      .text-wrapper {
        margin-top: 20px;
        line-height: 30px;
        text-align: left;
        .is-bold {
          font-weight: bold;
        }
      }
    }
  }
</style>
